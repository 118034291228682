import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import { BackgroundColorCustomType, BackgroundColorType, BackgroundImageType } from "../types/Types";
import { colors } from "../styles/variables";

const StyledLayoutRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0 31px;
  }
  
  &.red {
    background-color: ${colors.red};
  }
  &.orange {
    background-color: ${colors.orange};
  }
  &.yellow {
    background-color: ${colors.yellow};
  }
  &.green {
    background-color: ${colors.green};
  }
  &.blue {
    background-color: ${colors.blue};
  }
  &.transparent {
    background-color: ${colors.transparent};
  }

`;

interface LayoutRootProps {
  backgroundColor: BackgroundColorType;
  backgroundColorCustom: BackgroundColorCustomType;
  backgroundImage: BackgroundImageType;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({
  children,
  backgroundColor,
  backgroundColorCustom,
  backgroundImage
}) => {

  const bgImage = backgroundImage && backgroundImage.length > 0 ? "url(" +backgroundImage[0].url + ")?auto=format,compress"  : "none"

  return (
    <StyledLayoutRoot className={`layout-root ${backgroundColor}`} style={{ backgroundColor: backgroundColorCustom, backgroundImage: `${bgImage}` }} >
      {children}
    </StyledLayoutRoot>
  );
};

export default LayoutRoot;
