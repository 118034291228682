import * as React from "react";
import styled from "styled-components";

const StyledLayoutMain = styled.main`
  display: flex;
  `;
  
  const LayoutMainInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface LayoutMainProps {
  className?: string;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={`layout-main ${className}`}><LayoutMainInner>{children}</LayoutMainInner></StyledLayoutMain>
);

export default LayoutMain;
