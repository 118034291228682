import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { NavigationEntryType } from "../types/Types";
import NavigationMenu from "./NavigationMenu";
import { colors, fonts } from "../styles/variables";
import { useLocation } from "@reach/router";

const StyledListItem = styled.li`
  list-style: none;

  ul {
    display: none;
  }

  a {
    border-bottom: solid 1.5px transparent;
    transition: border-bottom 0.15s;
    padding: 0.1rem 0.1rem 0.05rem 0.1rem;
    display: inline-block;

    &.is-active {
      text-transform: uppercase;
      font: ${fonts.navigationMainActive};

      & + div ul {
        display: block;
        padding-left: 25px;
      }
    }
    &:hover {
      text-decoration: none;
      border-bottom: solid 1.5px ${colors.black};
    }

    &.is-home-inactive {
      text-transform: inherit;
      font: ${fonts.navigation};
    }
  }

  ul {
    a {
      &.is-active {
        text-transform: none;
        text-decoration: none;
        border-bottom: solid 1.5px ${colors.black};
        color: ${colors.black};
        font: ${fonts.navigation};
      }
    }
  }
  `
const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

type NavigationProps = {
  items?: NavigationEntryType[];
  item: NavigationEntryType;
};

const NavigationMenuList: React.FC<NavigationProps> = ({ item, items }) => {
  const location = useLocation();

  return (
    <>
      {item.showInNavigation &&
        <StyledListItem key={item.id}>
          <StyledLink
            to={`/${item.uri}`}
            activeClassName="is-active"
            partiallyActive={true}
            className={ item.uri.split("/").length !== 2 && location.pathname.split("/").length !== 2 && "is-home-inactive"}
          >
            {item.title}
          </StyledLink>
          {items && items.length > 0 && (
            <NavigationMenu items={items} isMobileNavOpen={false} />
          )}
        </StyledListItem>
      }
    </>
  )
};

export default NavigationMenuList;
