import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StyledContainer = styled.div`
  position: relative;
  max-width: 950px;
  padding: 0 17px;

  &.no-space {
    padding: 0;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0 31px;

    &.no-space {
      padding: 0 31px;
    }
  }
`;

interface ContainerProps {
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => (
  <StyledContainer className={className ? className : ""}>{children}</StyledContainer>
);

export default Container;
