import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, fonts } from "../styles/variables";
import _ from "lodash";


const StyledLanguageChooser = styled.div`
  margin-top: 15px;
`
const LanguageChooserInner = styled.div`
`
const StyledLink = styled(Link)`
  color: ${colors.black};
  text-decoration: none;
  padding: 0.1rem 0.1rem 0.05rem 0.1rem;
  text-transform: uppercase;
  margin-right: 5px;

  &:hover {
    text-decoration: none;
    border-bottom: solid 2px ${colors.black};
  }

  &.is-active {
      text-decoration: none;
      border-bottom: solid 2px ${colors.black};
      color: ${colors.black};
      font: ${fonts.navigation};
    
  }
`
export type LanguageChooserType = {
  navEntriesPerId: {
    siteId: number;
    id: number;
    uri: string;
    language: string;
  }[];
}


const LanguageChooser: React.FC<LanguageChooserType> = ({ navEntriesPerId }) => (
  <StyledLanguageChooser>
    <LanguageChooserInner>
      {navEntriesPerId && navEntriesPerId.length > 0 &&
        (navEntriesPerId.map((item) => {
          return (
            <StyledLink
              key={`${item.id}-${item.language}`}
              to={`/${_.includes(item.uri, "__home__") ? item.language : item.uri}`}
              activeClassName="is-active"
            >
              {item.language}
            </StyledLink>)
        }))
      }
    </LanguageChooserInner>
  </StyledLanguageChooser >
);

export default LanguageChooser;
