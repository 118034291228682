import * as React from "react";
import styled from "styled-components";
import NavigationMenuList from "./NavigationMenuList";
import { NavigationEntryType } from "../types/Types";

const StyledNavigationMenuWrapper = styled.div`
`
const StyledNavigationMenu = styled.ul`
  margin: 0;
  padding: 0;

  &.is-open {
    display: block;
  }
`

type NavigationProps = {
  items: NavigationEntryType[];
  isMobileNavOpen: boolean;
  navEntriesPerId: LanguageChooserType;
};

const NavigationMenu: React.FC<NavigationProps> = ({ items, isMobileNavOpen, navEntriesPerId, isHome }) => {



  return (
    <StyledNavigationMenuWrapper>
      <StyledNavigationMenu className={isMobileNavOpen ? "is-open" : ""}
      >
        {items && items.length > 0 && items.map((item) => (
          <NavigationMenuList item={{
            id: item.id,
            title: item.title,
            uri: item.uri.includes("__home__")
            ? `${item.language}`
            : `${item.uri}`,
            showInNavigation: item.showInNavigation,
            items: item.items,
          }} key={item.id} {...item} />
        ))}
      </StyledNavigationMenu>
    </StyledNavigationMenuWrapper>
  )
};

export default NavigationMenu;