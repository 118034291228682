import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import "../styles/base.css";
import "../styles/fonts.css";
import "../styles/print.css";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import {
  ImageType,
  NavigationEntryType,
  backgroundColorCustomType,
  backgroundColorType,
} from "../types/Types";
import Footer from "../components/Footer";
import { useState } from "react";
import styled from "styled-components";
import NavigationMenu from "../components/NavigationMenu";
import { breakpoints, heights } from "../styles/variables";
import LanguageChooser from "../components/LanguageChooser";

const LayoutWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 50px;
  position: relative;
`;
const Sidebar = styled.div`
  position: fixed;
  inset: 0 0 auto 0;
  display: none;
  z-index: 9;
  padding: ${heights.header}px 17px 35px 17px;
  background: darkgrey;

  &.is-open {
    display: block;
    background: ;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    position: static;
    padding: ${heights.header}px 0;
    width: 180px;
    display: block;
    background: transparent;

    &.is-open {
      background: transparent;
    }
  }
`;
const StickyContainer = styled.div`
  @media screen and (min-width: ${breakpoints.md}px) {
    position: sticky;
    top: 35px;
  }
`;
interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
  cms: {
    navigationEntries: NavigationEntryType[];
  };
  title: string;
  description: string;
  id: string;
  backgroundColor: backgroundColorType;
  backgroundColorCustom: backgroundColorCustomType;
  backgroundImage: ImageType;
}

const DefaultLayout: React.FC<NavigationEntryType> = ({
  children,
  siteId,
  id,
  language,
  backgroundColor,
  backgroundColorCustom,
  backgroundImage,
  title,
  description
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query DefaultLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          cms {
            globalSet {
              ... on CMS_footerMenu_GlobalSet {
                id
                name
                globalNavigationMenu(siteId: [1,2,3,4]) {
                  id
                  uri
                  title
                  language
                  siteId
                }
              }
            }
            navigationEntries: entries(
              section: ["pages"]
              level: 1
              siteId: [1, 2, 3, 4]
            ) {
              siteId
              id
              uri
              title
              language
              ... on CMS_pages_pages_Entry {
                id
                showInNavigation
              }
              items: children {
                title
                uri
                id
                ... on CMS_pages_pages_Entry {
                  id
                  showInNavigation
                }
              }
            }
            languageChooser: entries(section: ["pages", "events"], siteId: [1, 2, 3, 4]) {
              siteId
              id
              uri
              language
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => {
        const navEntriesPerLanguage = data.cms.navigationEntries.filter(
          (item) => item.siteId === siteId
        )
        const navEntriesPerId = data.cms.languageChooser.filter(
          (item: { id: string; }) => item.id === id
        )
        const footerMenu = data.cms.globalSet.globalNavigationMenu.filter(
          (item: { siteId: any; }) => item.siteId === siteId
        )

        const titleString = (param: "de"|"fr"|"it"|"en") => {
          switch(param){
            case "de":
              return title ? `${title} – Hotel Restaurant – Militärkantine St.Gallen – schön nachhaltig` : ""
            case "fr": 
              return title ? `${title} – Hôtel Restaurant – Militärkantine Saint-Gall – magnifiquement durable` : ""
            case "it": 
              return title ? `${title} – Hotel Ristorante – Militärkantine San Gallo – splendidamente sostenibile` : ""
            default:
              return title ? `${title} – Hotel Restaurant – Militärkantine St.Gallen – beautifully sustainable` : ""
          }
        }

        return (
          <LayoutRoot
            backgroundColor={backgroundColor}
            backgroundColorCustom={backgroundColorCustom}
            backgroundImage={backgroundImage}
          >
            <Helmet
              title={titleString(language)}
              meta={[
                {
                  name: "description",
                  content: description,
                },
              ]}
            >
              <html lang={language} />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/assets/favicon/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/assets/favicon/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/assets/favicon/favicon-16x16.png"
              />
              <link rel="manifest" href="/assets/favicon/site.webmanifest" />
              <link
                rel="mask-icon"
                href="/assets/favicon/safari-pinned-tab.svg"
                color="#5bbad5"
              />
              <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
              <meta name="msapplication-TileColor" content="#f5e4ef" />
              <meta
                name="msapplication-config"
                content="/assets/favicon/browserconfig.xml"
              />
              <meta name="theme-color" content="#f5e4ef"></meta>
            </Helmet>
            <Header
              isMobileNavOpen={isMobileNavOpen}
              setIsMobileNavOpen={setIsMobileNavOpen}
              language={language ? language : "en"}
            />
            <LayoutWrapper className="layout-wrapper">
              <Sidebar className={isMobileNavOpen ? "is-open" : ""}>
                <StickyContainer>
                  <NavigationMenu
                    items={navEntriesPerLanguage}
                    isMobileNavOpen={isMobileNavOpen}
                    navEntriesPerId={navEntriesPerId}
                  />
                  <LanguageChooser navEntriesPerId={navEntriesPerId} />
                </StickyContainer>
              </Sidebar>
              <LayoutMain>{children}</LayoutMain>
            </LayoutWrapper>
            <Footer
              navigationMenu={footerMenu}
              isShareOpen={isShareOpen}
              setIsShareOpen={setIsShareOpen}
            />
          </LayoutRoot>
        );
      }}
    />
  );
};

export default DefaultLayout;
