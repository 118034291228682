import * as React from "react";
import styled from "styled-components";
import PhoneIcon from "../../static/assets/icons/tel.svg";
import EmailIcon from "../../static/assets/icons/let.svg";
import PlaceIcon from "../../static/assets/icons/map.svg";
import GiftIcon from "../../static/assets/icons/gift.svg";
import Icon from "./Icon";
import NavOpener from "../../static/assets/icons/navigation-opener.svg";
import NavCloser from "../../static/assets/icons/navigation-closer.svg";

import { heights, colors, breakpoints } from "../styles/variables";
import Container from "./Container";
import LanguageChooser from "./LanguageChooser";
import { Dispatch, SetStateAction } from "react";

const StyledHeader = styled.header`
  position: fixed;
  height: ${heights.header}px;
  inset: 0 0 auto 0;
  z-index: 10;
`
const HeaderContainer = styled(Container)`
  max-width: 100%;
`
const HeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-center;
  justify-content: space-between;
`
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 1;
`
const Divider = styled.div`
  border-left: solid 1px black;
  height: 2em;
  padding-left: 10px;
  margin-left: 10px;
`
const StyledIcon = styled(Icon)``

const StyledLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
  border-bottom: solid 1.5px transparent;
  transition: border-bottom 0.15s;
  cursor: pointer;

  &:hover {
    border-bottom: solid 1.5px black;
  }
`
const StyledIconLink = styled.a `
  width: 100%;
`

const StyledMailtoLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
  border-bottom: solid 1.5px transparent;
  transition: border-bottom 0.15s;
  cursor: pointer;

  &:hover {
    border-bottom: solid 1.5px black;
  }
`
const MobileMenu = styled.div`
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.md}px) {
    display: none;
  }
`

interface HeaderProps {
  isMobileNavOpen: boolean;
  setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>;
  language: string;
}

const Header: React.FC<HeaderProps> = ({
  isMobileNavOpen,
  setIsMobileNavOpen,
  language
}) => {

  return (
    <StyledHeader className="header">
      <HeaderContainer>
        <HeaderInner>
          <HeaderLeft>
            <MobileMenu
              onClick={() => {
                setIsMobileNavOpen(!isMobileNavOpen);
              }}
            >
              {isMobileNavOpen ? (
                <StyledIcon><img src={NavOpener} alt="Navigation" /></StyledIcon>
              ) : (
                <StyledIcon><img src={NavCloser} alt="Navigation" /></StyledIcon>
              )}
            </MobileMenu>
            {/* <Divider /> */}
            <LanguageChooser />
          </HeaderLeft>
          <HeaderRight>
            <StyledIcon><StyledIconLink href="tel:+41712791000"><img src={PhoneIcon} alt="PhoneIcon" /></StyledIconLink></StyledIcon>
            <StyledIcon><StyledIconLink href="mailto:essen@militaerkantine.ch"><img src={EmailIcon} alt="EmailIcon" /></StyledIconLink></StyledIcon>
            <StyledIcon><StyledIconLink href="https://maps.app.goo.gl/GuAk6oDHPQDetRfJ7" target="blank"><img src={PlaceIcon} alt="PlaceIcon" /></StyledIconLink></StyledIcon>
            <StyledIcon><StyledIconLink href="https://shop.e-guma.ch/militaerkantine/de/gutscheine" target="blank"><img src={GiftIcon} alt="GiftIcon" /></StyledIconLink></StyledIcon>
            <Divider />
            <StyledMailtoLink href="mailto:essen@militaerkantine.ch">
              {language === "de" && "Tisch reservieren"}
              {language === "fr" && "Réserver une table"}
              {language === "it" && "Prenota un tavolo"}
              {language === "en" && "Book a table"}
            </StyledMailtoLink>
            <Divider />
            <StyledLink href={`https://tbooking.toubiz.de/TDS00020010476575469/tbooking/?globalReset=1&lang=${language}`} target="_blank">
              {language === "de" && "Zimmer buchen"}
              {language === "fr" && "Réserver des chambres"}
              {language === "it" && "Prenota le camere"}
              {language === "en" && "Book rooms"}
            </StyledLink>
          </HeaderRight>
        </HeaderInner>
      </HeaderContainer>
    </StyledHeader>
  )
};

export default Header;
