/* eslint-disable max-len */

export const colors = {
  white: "#ffffff",
  black: "#000000",
  red: "#f5e4ef",
  orange: "#f6a320",
  yellow: "#fff9bd",
  green: "#e0ebcd",
  blue: "#e9f4f8",
  transparent: "transparent",
};

// font shorthand Property
// font-style font-variant font-weight font-stretch font-size/line-height font-family
export const fonts = {
  headline: 'normal normal normal 400 30px / 100% "Bely Bold Italic", serif',
  text: 'normal normal normal 400 25px / 140% "Bely Regular", serif',
  textsmall: 'normal normal normal 400 25px / 100% "Bely Regular", serif',
  textitalic: 'italic normal normal 400 25px / 100% "Bely Italic", serif',
  title: 'normal normal normal 400 20px / 150% "Mundial Regular", sans-serif',
  supertitle:
    'normal normal normal 400 60px / 100% "Mundial Regular", sans-serif',
  infotext:
    'normal normal normal 400 18px / 110% "Mundial Regular", sans-serif',
  navigation:
    'normal normal normal 400 18px / 110% "Mundial Regular", sans-serif',
  navigationMainActive:
    'normal normal normal 300 18px / 120% "Mundial Light", sans-serif',
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
};

export const heights = {
  header: 35,
};
