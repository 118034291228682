import * as React from "react";
import styled from "styled-components";
import MikaLogo from "../../static/assets/logos/mika.svg";
import { breakpoints, colors } from "../styles/variables";
import Container from "./Container";
import { Link } from "gatsby";
import { Dispatch, SetStateAction } from "react";
import Share from "./Share";

const StyledFooter = styled.footer`
  padding: 0 0 40px 0;
  margin-top: 30px;
  font-size: 12px;
`;
const FooterContainer = styled(Container)`
  max-width: 100%;
`;
const FooterUpper = styled.div`
  padding: 0 0 15px 0;
  display: flex;
  justify-content: flex-end;
`;
const StyledShare = styled.div`
  cursor: pointer;
`;
const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  border-top: solid 1px ${colors.black};
  padding-top: 30px;

  @media screen and (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex-basis: 400px;
    }

    & > *:nth-of-type(2) {
      flex-basis: 200px;
    }
  }
`;
const FooterLeft = styled.div`
  text-align: center;
  line-height: 1.3;

  a {
    text-decoration: none;
    color: ${colors.black};
    border-bottom: solid 1.5px transparent;
    transition: border-bottom 0.15s;

    &:hover {
      border-bottom: solid 1.5px black;
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    text-align: left;
    order: 0;
  }
`;
const FooterLogo = styled.div`
  order: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    order: 0;
  }
`;

const FooterRight = styled.div`
  text-align: center;
  line-height: 1.3;

  @media screen and (min-width: ${breakpoints.md}px) {
    text-align: right;
  }
`;

const FooterRightInner = styled.div`
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
  border-bottom: solid 1.5px transparent;
  transition: border-bottom 0.15s;
  padding: 0 3px 0 3px;

  &:hover {
    border-bottom: solid 1.5px black;
  }
`;
interface FooterProps {
  navigationMenu: [];
  isShareOpen: boolean;
  setIsShareOpen: Dispatch<SetStateAction<boolean>>;
}

const Footer: React.FC<FooterProps> = ({
  navigationMenu,
  isShareOpen,
  setIsShareOpen,
}) => {

  return (
    <StyledFooter className="footer">
      <FooterContainer>
        <FooterUpper>
          <StyledShare
            onClick={() => {
              setIsShareOpen(!isShareOpen);
            }}
          >
            <Share isShareOpen={isShareOpen} />
          </StyledShare>
        </FooterUpper>
        <FooterInner>
          <FooterLeft>
            Militärkantine St.Gallen AG
            <br />
            Kreuzbleicheweg 2, 9000 St.Gallen
            <br />
            <a href="mailto:schlafen@militaerkantine.ch">
              schlafen@militaerkantine.ch
            </a>
            , <a href="tel:+41712791000">+41 71 279 10 00</a>
            <br />
          </FooterLeft>
          <FooterLogo>
            <Link to="/de">
              <img src={MikaLogo} />
            </Link>
          </FooterLogo>
          <FooterRight>
            <FooterRightInner>
              {navigationMenu &&
                navigationMenu.length > 0 &&
                navigationMenu.map((item, index) => {
                  const isLineBreak =
                    index == Math.floor(navigationMenu.length / 3) ||
                    index == Math.floor((navigationMenu.length / 3) * 2);
                  return (
                    <span key={`${item.id}-${item.language}`}>
                      <StyledLink
                        to={`/${item.uri}`}
                        activeClassName="is-active"
                      >
                        {item.title}
                      </StyledLink>
                      {isLineBreak && <br />}
                    </span>
                  );
                })}
            </FooterRightInner>
          </FooterRight>
        </FooterInner>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
