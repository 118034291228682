import * as React from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  width: 19px;
  height: 19px;
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 3px;
  
  img {
    width: 100%;
  }
`;

const Icon: React.FC = ({ children }) => (
  <StyledIcon>
    {children}
  </StyledIcon>
);

export default Icon;
