import * as React from "react";
import styled from "styled-components";
import Instagram from "../../static/assets/icons/insta.svg";
import Facebook from "../../static/assets/icons/fb.svg";
import Icon from "./Icon";
import { Link } from "gatsby";
import ShareIcon from "../../static/assets/icons/share.svg";

const StyledShare = styled.div``;
const ShareInner = styled.div``;
const ShareItemsContainer = styled.div`
  display: flex;

  &.rtl {
    direction: rtl;
  }

`;

const ShareItemsContainerInner = styled.div`
  display: flex;
  animation-duration:0.5s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      max-height: 0px;
      transform: scale(0);
      opacity: 0;
    }

    to {
      max-height: 1000px;
      transform: scale(1);
      opacity: 1;
    }
  }
`
const StyledIconShare = styled.div`
  padding-left: 13px;
`;

interface ShareProps {
  isShareOpen: boolean;
}

const Share: React.FC<ShareProps> = ({ isShareOpen }) => {
  return (
    <StyledShare>
      <ShareInner>
        {isShareOpen ? (
          <ShareItemsContainer className="rtl">
            <StyledIconShare>
              <Icon>
                <img src={ShareIcon} alt="Share" />
              </Icon>
            </StyledIconShare>
            <ShareItemsContainerInner>
              <Link
                to="https://www.instagram.com/militarkantinest.gallen/"
                target="_blank"
              >
                <Icon>
                  <img src={Instagram} alt="Instagram" />
                </Icon>
              </Link>
              <Link
                to="https://www.facebook.com/Militaerkantine/"
                target="_blank"
              >
                <Icon>
                  <img src={Facebook} alt="Facebook" />
                </Icon>
              </Link>
            </ShareItemsContainerInner>
          </ShareItemsContainer>
        ) : (
          <StyledIconShare>
            <Icon>
              <img src={ShareIcon} alt="Share" />
            </Icon>
          </StyledIconShare>
        )}
      </ShareInner>
    </StyledShare>
  );
};

export default Share;
